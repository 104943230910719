import React from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import PartnersSection from '../../components/patnersSection'
import headImg from "./../../assets/images/head_img.png"
import waveIcon from "./../../assets/icons/vague_header.png"
import cardImg1 from "./../../assets/images/Rectangle1.jpg";
import cardImg2 from "./../../assets/images/service-repair.png";
import cardImg3 from "./../../assets/images/service-cleaning.png";
import cardImg4 from "./../../assets/images/equipments.png";
import cardImg5 from "./../../assets/images/engine-store.png";
import cardImg6 from "./../../assets/images/other-services.png";
import OurServingPortsImg from "./../../assets/images/OurServingPorts_img.png";
import headSndImg from "./../../assets/images/head_img_snd.png"
import {cardInterface} from "../../utilities/cardInterface";
import { useSelectedLink } from './../../utilities/fonctions'
import Carousel from "react-multi-carousel";

const responsive = {
  bigTablet: {
    breakpoint: { max: 767, min: 651 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 650, min: 451 },
    items: 1.5
  },
  mobileSize: {
    breakpoint: { max: 450, min: 381 },
    items: 1.2
  },
  mobile: {
    breakpoint: { max: 380, min: 351 },
    items: 1.1
  },
  smallMobile: {
    breakpoint: { max: 350, min: 0 },
    items: 1
  }
};

export function Card({ImgScr,title,description}:cardInterface){

  return(
    <div className='relative flex flex-col h-[460px] services-card '>
      <div className='card-img'>
        <img src={ImgScr} alt="card img 1" />
      </div>
      <div  className='mt-6'>
        <div className='card-title'>{title}</div>
        <div className='card-description mt-4'>{description}</div>
      </div>
    </div>
  )
}

export function PortItem({ImgScr,title}:cardInterface){
  return(
    <div className='flex flex-col gap-4 mx-4'>
      <div className='w-[100px] rounded-full overflow-hidden'>
        <img src={ImgScr} className='' alt="card img 1" />
      </div>
      <div className='w-[100px] text-center portItem-country'>{title}</div>
      
    </div>
  )
}

export default function HomePage() {

  const { selectedLink, ScrollTophandleClick } = useSelectedLink();


  const servicesListCard = [
    {
      id: 1,
      ImgScr: cardImg1,
      title: "Food and cabin supplies",
      description: "We supply a wide range of foodstuffs and utensils for your cabins."
    },
    {
      id: 2,
      ImgScr: cardImg2,
      title: "Repairs maintenance",
      description: "We offer repair and maintenance services for your equipment"
    },
    {
      id: 3,
      ImgScr: cardImg3,
      title: "Marine Oils, Solvents, Painting and clearners",
      description: "We offer marine oils, high-performance solvents, effective paints and cleaners. "
    },
    {
      id: 4,
      ImgScr: cardImg4,
      title: "Safety Equipement",
      description: "We provide advanced solutions for personal and industrial protection, meeting the most stringent standards."
    },
    {
      id: 5,
      ImgScr: cardImg5,
      title: "Engine Deck Stores",
      description: "we supply top-quality equipment. From parts to tools, our range meets your marine needs with excellence."
    },
    {
      id: 6,
      ImgScr: cardImg6,
      title: "Others Services ( British Admirality & Removals)",
      description: "We provide efficient sanitation, removal, unloading and other services. "
    }
  ]
 
  return <>
          <div className='head head-content w-full flex items-center justify-center'>
            <div className="relative w-full h-full max-width flex flex-col bigTablet:flex-row items-end bigTablet:items-center gap-x-[50px] customSize:gap-x-[133px] justify-center bigTablet:justify-between px-[50px] desktop:px-[125px]">
              <div className='relative flex flex-col gap-y-[40px] bigTablet:gap-y-[64px] w-[100%] bigTablet:w-[496px] items-center bigTablet:items-start'>
                <div className='flex flex-col gap-y-[32px]'>
                  <div className='text-center desktop:text-left head-title'>
                    Your Route to <br/>Maritime <span className='head-title-highlight'>Excellence!</span>
                  </div>
                  <div className='text-center desktop:text-left head-text'>
                    Personalised, reliable service 24/7. Constant commitment to total on-board satisfaction with exceptional quality.
                  </div>
                </div>
                <Link to="/contact" onClick={() => ScrollTophandleClick('contact-link')} className=' w-[100%] bigTablet:w-[306px] ' >
                  <div className='head-contact-btn w-full bigTablet:w-[321px] p-4 desktop:p-3'>
                    Contact us
                  </div>
                </Link>  
              </div>
              <div className='relative flex items-center justify-end max-w-[560px] desktop:w-full desktop:justify-end mt-[89px] bigTablet:mt-[0px] mr-[-100px]  bigTablet:mr-[0px]  '>
                <img src={headImg} alt="img slide" className='hidden bigTablet:block w-full h-auto'/>
                <img src={headSndImg} alt="img slide 2"  className='block w-full max-w-[479px] min-w-[200px] bigTablet:hidden' />
              </div>
              <div className='absolute hidden bigTablet:block -right-[150px] top-[7px] '>
                <img className='w-[271px] h-auto' src={waveIcon} alt="wave img"/>
              </div>
            </div>
          </div>
          <div className="overflow-hidden h-fit pb-0 mb-0 w-ful flex items-center justify-center mt-[80px] mb-[80px] bigTablet:mb-[100px] ">
            <div className="w-full max-width">
                <PartnersSection showCertifications/>
              </div>
          </div> 
          <div className='services-section pb-[125px] w-full flex items-center justify-center'>
            <div className="w-full max-width px-[0px] bigTablet:px-[20px] desktop:px-[0px]">
              <div className='w-full flex flex-col pt-[54px] gap-10 bigTablet:gap-16'>
                <div className='text-content'>
                  <div className='title'>
                  Our services
                  </div>
                  <div className='text w-[80vw] desktop:w-[891px]'>
                    Our priority is to keep our customers satisfied, so we offer a comprehensive range of maritime services.
                  </div>
                </div>

                <div className="hidden bigTablet:flex justify-center w-full">
                  <div className= 'grid grid-cols-2 size992:grid-cols-3 gap-x-[20px] gap-y-[84px] services-list max-w-[1123px] '>
                      {
                        servicesListCard.map((card: cardInterface, index: number)=>{
                            return(
                              <Card ImgScr={card.ImgScr} title={card.title} description={card.description} />
                            )
                        })
                      }
                  </div>
                </div>
                <div className="w-full block bigTablet:hidden">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="swiper-container carousel8 pt-4 auctions"
                  removeArrowOnDeviceType={["bigTablet", "tablet", "mobileSize", "mobile", "smallMobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="px-[12px]"
                >
                  {
                    servicesListCard.slice(0, 3).map((card: cardInterface, index: number)=>{
                        return(
                          <Card ImgScr={card.ImgScr} title={card.title} description={card.description} />
                        )
                    })
                  }
                </Carousel>
                </div>
                <div className="w-full block bigTablet:hidden">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="swiper-container carousel8 pt-4 auctions"
                  removeArrowOnDeviceType={["bigTablet", "tablet", "mobileSize", "mobile", "smallMobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="px-[12px]"
                >
                  {
                    servicesListCard.slice(3, servicesListCard.length).map((card: cardInterface, index: number)=>{
                        return(
                          <Card ImgScr={card.ImgScr} title={card.title} description={card.description} />
                        )
                    })
                  }
                </Carousel>
                </div>
              </div>
            </div>
          </div>

          <div className='OurServingPorts-section w-full flex items-center justify-center pt-[124px] pb-[124px]'>
            <div className="w-full max-width px-[20px] desktop:px-[126px] gap-16 desktop:gap-[100px] flex flex-col desktop:flex-row desktop:justify-between items-center">
                <div className='inline-flex flex-col gap-4 OurServingPorts-text '>
                  <div className='OurServingPorts-title max-w-[398px] '>We serve your vessels in west Africa ports</div>
                  <div className='OurServingPorts-description max-w-[542px] '>
                    We already operate efficiently in more than five (05) ports in the sub-region
                  </div>
                </div>
                <div className='OurServingPorts-img w-[411px] hidden desktop:block'>
                  <img src={OurServingPortsImg} alt="Our Serving Ports img" width={411} />
                </div>
            </div>
          </div>
  </>
}
