import React, {FormEvent, useRef, useState, useEffect} from 'react'
import { ReactSVG } from 'react-svg';
import closeIcon from "./../../assets/icons/closeBig.svg" 
import selectFileIcon from "./../../assets/icons/file-earmark-pdf-fill.svg"
import docIcon from "../../assets/icons/Google docs.svg"
import trashIcon from "../../assets/icons/trash2.svg"
import { troncatenerString } from '../../utilities/fonctions';
import white_logo from '../../assets/images/avinato_white_logo.png'

interface GetFreeQuoteModalProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function GetFreeQuoteModal({setOpenModal}:GetFreeQuoteModalProps) {
  const nameRef = useRef<HTMLInputElement>(null);
  const companyNameRef = useRef<HTMLInputElement>(null);
  const companyAddressRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  
  const handleFileSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    setSelectedFiles(files);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const files = e.target.files;
    if (files && files.length > 0) {
      if (selectedFiles && selectedFiles?.length > 0) {
      const updatedFiles = new DataTransfer();
      Array.from(selectedFiles).forEach(file => {
        updatedFiles.items.add(file);
      });
      Array.from(files).forEach(file => {
        updatedFiles.items.add(file);
      });
      setSelectedFiles(updatedFiles.files);
    } else {
      setSelectedFiles(files);
    }
    
  }
};

const handleCloseModal = () => {
  setOpenModal(false);
  document.body.classList.toggle("sectionDivStyle")

};

  const handleFileDelete = (fileName: string) => {
    if (selectedFiles) {
      const updatedFiles = Array.from(selectedFiles).filter(file => file.name !== fileName);
      const newFiles = new DataTransfer();
      Array.from(updatedFiles).forEach(file => {
        newFiles.items.add(file);
      });
      setSelectedFiles(updatedFiles.length > 0 ? newFiles.files : null)
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (nameRef.current && emailRef.current && messageRef.current  && selectedFiles && fileInputRef.current) {
      const name: string = nameRef.current.value;
      const CompanyName: string = nameRef.current.value;
      const Companyaddress: string = nameRef.current.value;
      const email: string = emailRef.current.value;
      const message: string = messageRef.current.value;
      const files: FileList | null = fileInputRef.current.files;
    }
  };



  return (
    <div className={`fixed z-999 top-[87px] w-full h-[calc(100vh-87px)] py-0 bigTablet:py-6 overflow-y-auto no-scrollbar  bg-[#F0F0F0] backdrop-blur-xl bg-opacity-10 flex items-center justify-center`}>
       <div className='h-full desktop:h-auto overflow-y-auto no-scrollbar qote-modal size960:rounded-2xl bg-white w-full max-w-[959px]'>
            <div className='relative top-0 getFreeQuote-modal w-full '>
              <div className='hidden bigTablet:flex cursor-pointer items-center py-[48px] w-full text-center justify-between'>
                <div className='w-full title'>Get free quote</div>
                <ReactSVG src={closeIcon} onClick={handleCloseModal} className='absolute float-right right-11'  />
              </div>
              <div className="bigTablet:hidden flex flex-col w-full px-5 py-4">
                <div className='flex cursor-pointer items-center  w-full justify-between'>
                  <img src={white_logo} className='w-20 h-auto' />
                  <ReactSVG src={closeIcon} onClick={handleCloseModal} />
                </div>
                <div className='flex cursor-pointer items-center w-full text-center justify-center mt-[20px] mb-[44px] '>
                  <div className='w-full title'>Get free quote</div>
                </div>
              </div>
              <div className="w-full flex justify-center px-[0px] bigTablet:px-[43.5px] pb-[41px] ">
                <div className='form pt-[0px] bigTablet:pt-[52px] bg-[#fff] rounded-xl px-5 bigTablet:px-6 w-full max-w-[959px]'>
                  <form onSubmit={handleSubmit} className='grid grid-cols-1 px-0 bigTablet:px-6 bigTablet:grid-cols-2 tablet:px-0 gap-6' >
                    <div>
                      <label htmlFor="name">Name</label>
                      <input type="text" id="name" ref={nameRef} placeholder="Enter your full name" />
                    </div>
                    <div>
                      <label htmlFor="companyName">Company name</label>
                      <input type="text" id="companyName" ref={companyNameRef} placeholder="Enter your company name" />
                    </div>
                    <div>
                      <label htmlFor="companyAddress">Company address</label>
                      <input type="text" id="companyAddress" ref={companyAddressRef} placeholder="Enter your company address" />
                    </div>  
                    <div>
                      <label htmlFor="email">E-mail</label>
                      <input type="email" id="email" ref={emailRef} placeholder="Enter your e-mail address" />
                    </div>
                    <div>
                      <label htmlFor="message">Message</label>
                      <textarea id="message" ref={messageRef} placeholder="Enter your message"></textarea>
                    </div>
                  
                    <div className='w-full'>
                        <label htmlFor="files">Select Files</label>
                        <div className='file-input-box-modal'>
                          <ReactSVG src={selectFileIcon} />
                          <input
                            type="file"
                            id="files"
                            ref={fileInputRef}
                            onChange={handleFileInputChange}
                            style={{ display: 'none' }}
                            multiple
                          />
                          <span className='select-file-text'>Drag and drop or <button type="button" className='browse-file-btn' onClick={handleFileSelect}> Browse file</button>
                          </span>
                          <div onDrop={handleFileDrop} onDragOver={(e) => e.preventDefault()} />
                        </div>
                    </div>
                  </form>
                  {selectedFiles && (
                    <div
                      className='flex flex-row flex-wrap items-center justify-between mt-4 get-quote-container'
                    >
                      {Array.from(selectedFiles).map(file => (
                        <div className='flex flex-row w-full items-center justify-center file-item-box w-full tablet:max-w-[400px]' key={file.name}>
                          <div className='flex flex-row items-center justify-center gap-3 document-file'>
                            <ReactSVG src={docIcon} />
                            <div className='file-name'> {troncatenerString(file.name)}</div>
                          </div>

                          <div className='cursor-pointer delete-document' onClick={() => handleFileDelete(file.name)}>
                            <ReactSVG src={trashIcon} />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className='relative flex  items-center justify-center w-full px-1'>
                      <button className='relative w-[340px] my-[52px] submit-conctat-message-modal tablet:w-[400px]' type="submit">Send message</button>              
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  )
}


