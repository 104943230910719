import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg';
import { NavLink, useLocation } from "react-router-dom";
import avinatoLogo from "./../../assets/icons/avinato_logo.svg";
import envelopeIcon from "./../../assets/icons/envelope.svg";
import HandburgerMenuIcon from "./../../assets/icons/menu.svg"
import avinatoLogoMobile from "./../../assets/icons/AvinatoIcon.svg"
import gouvernailleIcon from "./../../assets/images/gouvernailleIcon.png"
import closeIcon from "./../../assets/icons/close.svg"
import { useSelectedLink } from './../../utilities/fonctions'
import classnames from 'classnames';
interface NavBarProps {
  handleOpenModal: () => void;
}

interface MenuProps {
  label: string
  link: string
  id: string
}

const menuItems : MenuProps[] = [
    {
      label: 'Home',
      link: '/',
      id: 'home-link'
    },
    {
      label: 'Our services',
      link: '/services',
      id: 'services-link'
    },
    {
      label: 'About us',
      link: '/about',
      id: 'about-link'
    },
    {
      label: 'Contact us',
      link: '/contact',
      id: 'contact-link'
    }
]

function Navbar({ handleOpenModal }: NavBarProps) {

  const [handBurgerMenuActive, setHandBurgerMenuActive] = useState<boolean>(false);
  const { selectedLink, ScrollTophandleClick } = useSelectedLink();


  const handBurgerMenuHandleClick = () => {
    setHandBurgerMenuActive(!handBurgerMenuActive)
  }

  const sectionNavLinkHandleClick = (link: string) => {
    ScrollTophandleClick(link);
    setHandBurgerMenuActive(!handBurgerMenuActive)
  }

  const NavHandleOpenModal = () => {
    handleOpenModal();
  }
  const {pathname} = useLocation();

  const getActiveClassname = (link?: string, linkExtend?: Array<string>) => {
      return classnames({
          'current-link-color': pathname === link
      })
  }

  return (
    <header className='relative z-[10000] flex flex-col w-full overflow-x-hidden bg-inherit container'>
      <div className='relative w-full bg-inherit py-4 navbar flex items-center justify-center'>
        <div className="w-full max-width flex justify-between bigTablet:gap-12 size992:gap-[20px] desktop:gap-[83px]">
          <div className='relative logo'>
          <NavLink
                to=""
                onClick={() => ScrollTophandleClick('home-link')}
                id='home-link'
              >
            <ReactSVG src={avinatoLogo} className='hidden bigTablet:block' />
            <ReactSVG src={avinatoLogoMobile} className='block bigTablet:hidden' />
          </NavLink>
          </div>

          <nav className='flex section-link size992:gap-x-[20px] desktop:gap-x-[97px] large-nav'>
            <div className='section-page-link gap-x-[56.67px]'>
              {menuItems.map((menu: MenuProps, index: number) => {
                return <NavLink
                key={index}
                to={menu.link}
                onClick={() => ScrollTophandleClick(menu.id)}
                id={menu.id}
                className={`menu-item ${getActiveClassname(menu.link)}`}
              >
                {menu.label} <div className='menu-underline' />
              </NavLink>
              })}
            </div>
            <div onClick={NavHandleOpenModal} className='flex justify-center cursor-pointer navbar-getFree-btn gap-x-3'>
              <ReactSVG src={envelopeIcon} />
              <p className='items-center'>Get free quote</p>
            </div>
          </nav>

          <div className='handburger-menu-icon' onClick={handBurgerMenuHandleClick}>
            {handBurgerMenuActive ? <ReactSVG src={closeIcon} /> : <ReactSVG src={HandburgerMenuIcon} />}
          </div>
        </div>

      </div>

      <div className={`${handBurgerMenuActive ? `menuIsActive` : `menuIsNotActive `} fixed bg-white z-10 h-[100vh] overflow-hidden pt-7 pl-8 pr-8 tablet:pl-16 tablet:pr-16 customSize:hidden w-full p-4 mt-[74px]`}>
        <div className='relative flex flex-col w-fit gap-[28px] handburger-menu-link '>
          <NavLink to="" onClick={() => { sectionNavLinkHandleClick("home-link") }} className={`${selectedLink === 'home-link' ? 'current-link-color' : 'text-main'}`}>Home</NavLink>
          <NavLink to="/services" onClick={() => { sectionNavLinkHandleClick("services-link") }} className={`${selectedLink === 'services-link' ? 'current-link-color' : 'text-main'}`}>Our services</NavLink>
          <NavLink to="/about" onClick={() => { sectionNavLinkHandleClick("about-link") }} className={`${selectedLink === 'about-link' ? 'current-link-color' : 'text-main'}`}>About us</NavLink>
          <NavLink to="/contact" onClick={() => { sectionNavLinkHandleClick("contact-link") }} className={`${selectedLink === 'contact-link' ? 'current-link-color' : 'text-main'}`}>Contact us</NavLink>
          <NavLink to="/" onClick={NavHandleOpenModal}  className={`${selectedLink === 'contact-link' ? 'current-link-color' : 'text-main'}`}>Get free quote</NavLink>

        </div>
        <img src={gouvernailleIcon} className={`${handBurgerMenuActive ? `apearIcon` : `desapearIcon`} absolute -z-10 min-h-[504px] min-w-[504px] float-right right-[-50%] tablet:-right-60 bottom-20`} alt="gouvernaille icon" />
      </div>
    </header>
  )
}

export default Navbar