import React, { useRef, useState } from 'react'
import './style/slide.css';
import patnerIcon1 from "./../../assets/images/brands/brand-1.jpg";
import patnerIcon2 from "./../../assets/images/brands/brand-2.jpg";
import patnerIcon3 from "./../../assets/images/brands/brand-3.jpg";
import patnerIcon5 from "./../../assets/images/brands/brand-5.png";

import certification1 from "./../../assets/images/certifications/certif-1.jpg";
import certification2 from "./../../assets/images/certifications/certif-2.jpg";
import certification3 from "./../../assets/images/certifications/certif-3.png";
import certification4 from "./../../assets/images/brands/brand-4.png";
export default function PartnersSection({showCertifications = false}: any) {

    const [partnerLogoList,setPartnerLogoList] = useState<any>([patnerIcon1, patnerIcon2, patnerIcon3, patnerIcon5]);
    const [certificationList, setCertificationList] = useState<any>([certification1, certification2, certification3, certification4]);
  return (
    <div className='flex flex-col items-center w-full patner-list-section gap-8'>
        <div className='flex flex-col gap-16 w-full slide-block'>
          <div className='text-content'>
            <div className='title'>
              We are member of
            </div>
          </div>
          <div className="overflow-hidden flex items-center gap-[20px] bigTablet:gap-[0px] justify-center bigTablet:justify-between flex-wrap bigTablet:flex-nowrap desktop:justify-center desktop:gap-[199px] w-full">
          {partnerLogoList.map((image:string, index:number) => (
                    <img src={image} key={index} className='relative' alt={`img patner ${index + 1}`} />
          ))}
          </div>
        </div>

        {showCertifications && <div className='flex flex-col gap-16 w-full slide-block'>
          <div className='text-content'>
            <div className='title'>
            We are certified
            </div>
          </div>
          <div className="overflow-hidden flex items-center justify-between bigTablet:justify-center gap-[20px] bigTablet:gap-x-[170px] w-full flex-wrap bigTablet:flex-nowrap px-6">
          {certificationList.map((image:string, index:number) => (
                  <img src={image} key={index} className='relative' alt={`img patner ${index + 1}`} />
          ))}
          </div>
        </div>}
    </div>
    
        
  )
}
