import React, { useEffect, useState } from 'react'

export const useSelectedLink = () => {
  const [selectedLink, setSelectedLink] = useState<string>("home-link");

  const ScrollTophandleClick = (linkId: string) => {
    window.scrollTo(0, 0);
    console.log(selectedLink);
    setSelectedLink(linkId);
    
  };
  

  return { selectedLink, setSelectedLink, ScrollTophandleClick };
};

export function troncatenerString(text: string): string {
  if (text.length <= 20) {
    return text;
  } else {
    return text.substring(0, 17) + '...';
  }
} 