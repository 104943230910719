import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import AvinatoLogo from "./../../assets/icons/avinato_logo.svg"
import facebokkIcon from "./../../assets/icons/facebook logo.svg"
import twitterIcon from "./../../assets/icons/Twitter logo.svg"
import linkedInIcon from "./../../assets/icons/linkedin logo.svg"
import instagramIcon from "./../../assets/icons/instagram logo.svg"
import mapImg from "./../../assets/images/map.png"
import { useSelectedLink } from './../../utilities/fonctions'


export default function Footer() {

  const { selectedLink,setSelectedLink,ScrollTophandleClick } = useSelectedLink();

  return (
    <footer className='overflow-x-hidden z-10 footer pt-40 mt-[-100px] w-full flex items-center justify-center'>
      <div className="w-full max-width px-[20px] desktop:px-[124px] desktop:pb-[70px] ">
        <div className='w-full relative flex flex-col items-center justify-start gap-8 tablet:gap-28 bigTablet:gap-12 tablet:items-start tablet:flex tablet:flex-row flex-wrap bigTablet:flex-nowrap tablet:justify-center bigTablet:justify-between footer-container pb-5 bigTablet:pb-0 '>
          <div className='contact flex flex-col gap-[28px] justify-center desktop:justify-start'>
          <ReactSVG src={AvinatoLogo} />
          <div className='flex flex-col gap-2 max-tablet:text-center contact-information'>
              <p>473, Rue Danpken, Bè Chateau</p>
              <p>+228 90 28 75 74</p>
              <p>06 P.O. Box: 60618</p>
          </div>
          <Link to="/contact" onClick={()=>{ScrollTophandleClick('contact-link')}} className='max-tablet:hidden'>
                <div className='footer-contact-btn tablet:w-52'>
                  Contact us
                </div>
              </Link>  
          </div>

          <div className='flex flex-col'>
            <div className='title max-tablet:text-center mb-[30px] '>Pages</div>
            <div className='page-link grid grid-cols-2 gap-x-[68px] gap-y-6 mb-[61px] bigTablet:mb-[93px] '>
            <Link
            to=""
            onClick={() => ScrollTophandleClick('home-link')}
            className='text'
          >
            Home
          </Link>
          <Link
            to="/about"
            onClick={() => ScrollTophandleClick('about-link')}
            className='text'
            
          >
            Our Story
          </Link>
          <Link
            to="/services"
            onClick={() => ScrollTophandleClick('services-link')}
            className='text'
          >
            Our Services
          </Link>
          <Link
            to="/contact"
            onClick={() => ScrollTophandleClick('contact-link')}
            className='text'
          >
            Contact us
          </Link>
            </div>

            <div className='flex-row hidden gap-6 tablet:flex social-media-link'>
              <a href="https://facebook.com/" target='_blank' rel="noreferrer">
              <ReactSVG src={facebokkIcon} />
              </a>

              <a href="https://twitter.com/" target='_blank' rel="noreferrer">
              <ReactSVG src={twitterIcon} />
              </a>

              <a href="https://www.linkedin.com/" target='_blank' rel="noreferrer">
              <ReactSVG src={linkedInIcon} />
              </a>
              <a href="https://www.instagram.com/" target='_blank' rel="noreferrer">
              <ReactSVG src={instagramIcon} />
              </a>

            </div>

          </div>

          <div className='location flex flex-col gap-[14px] justify-start '>
            <div className='title w-fit '>Our Location</div>
            <div className='overflow-hidden map rounded-2xl w-full'>
              <a href="https://maps.app.goo.gl/3PfEbMXn9kkcKr497" target="_blank" rel="noopener noreferrer">
                <img src={mapImg} alt="map" className='tablet:w-[400px] w-fit rounded-2xl ' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
