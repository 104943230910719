import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import Navbar from "./components/navbar";
import  AboutPage from "./pages/about";
import ContactPage from "./pages/contact";
import ServicesPage from "./pages/services";
import PageNotFound from "./pages/pageNotFound";
import Footer from "./components/footer";
import GetFreeQuoteModal from "./components/GetFreeQuoteModal";
import { useState,useEffect } from "react";

function App() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  
  const handleOpenModal = ()=>{
    setOpenModal(true)
    document.body.classList.toggle("sectionDivStyle")
  }

  return (
    <>
      <Navbar  handleOpenModal={handleOpenModal} />
      <div className="main-container">
        <Routes>
            <Route path="/" element ={<HomePage/>} />
            <Route path="/about" element ={<AboutPage/>} />
            <Route path="/services" element ={<ServicesPage/>} />
            <Route path="/contact" element ={<ContactPage/>} />
            <Route path="*" element ={<PageNotFound/>} />
        </Routes>
        <Footer />   
      </div> 
      {openModal && <GetFreeQuoteModal setOpenModal={setOpenModal} />}
    </>
  );
}

export default App;
