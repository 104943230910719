import React from 'react'
import { useState } from 'react';
import { ReactSVG } from 'react-svg'
import aboutImg1 from './../../assets/images/About1.png'
import aboutImg2 from "./../../assets/images/About2.png"
import aboutImg3 from "./../../assets/images/About3.png"
import aboutImg4 from "./../../assets/images/About4.png"
import BannerBig from "./../../assets/images/about-banner.png"
import BannerSmall from "./../../assets/images/aboutBannerSmall.png"
import ManagerProfilImg from "./../../assets/images/manager-pic.png"
import PartnersSection from '../../components/patnersSection';
import { cardInterface } from "../../utilities/cardInterface";
import illustrationAboutPageImg from "./../../assets/images/illustrationAboutPAge.png"



const aboutListCard = [
  {
    id: 1,
    btnTitle: "Why choose us",
    ImgScr: aboutImg1,
    title: "We are Ship Suppliers",
    description: "We are Ship Suppliers professional with a broad variety of products of the best quality, provided directly to you. AVINATO is a company based in Lome and conveniently located. We take care of your orders in a fast and efficient way offering you priority at your satisfaction. In a way your ships crew can enjoy excellent quality products in west africa ports."
  },
  {
    id: 2,
    btnTitle: "Price and budget",
    ImgScr: aboutImg2,
    title: "Our prices are unbeatable",
    description: "We offer the best quality products at the lowest price possible."
  },
  {
    id: 3,
    btnTitle: "Our Vision",
    ImgScr: aboutImg3,
    title: "A secure and reliable supply channel ",
    description: "Our main purpose is to achieve a secure and reliable supply channel to ships arriving on west africa ports."
  },
  {
    id: 4,
    btnTitle: "Our Mission",
    ImgScr: aboutImg4,
    title: "Satisfaction, safety and low prices.",
    description: "Integral supply of provisions and services to ships arriving at Lome port, guaranteeing satisfaction, security and low prices."
  }

]


const trucateText = "AVINATO, founded in January 2004 by Armstrong Ayi AYAYI and managed by his sons Sylvain and Fabrice AYAYI since 2011, is a prominent ship supplies and marine services company in Togo. Specializing in ship chandler "
const fullText1 = `${"services at Lome-Port and Kpeme-Port, AVINATO has earned a stellar reputation in the industry. The company is a pioneer at Lome-Port and Kpeme-Port, AVINATO has earned a  stellar reputation in the industry.The company holds affiliations with global organizations like ISSA,  IMPA and SHIPSERV."}`;
const fullText2 = `${"A testament to AVINATO's dedication to quality, leadership, technology,and innovation came on April 15, 2018, when the company was honored with the Century International Quality ERA Award in the Gold category. This prestigious accolade, presented at the InterContinental Convention Hall Geneva by Mr. José E. Prieto, President of the bid, underscores AVINATO'sinternational recognition for its exceptional leadership in quality standards. This recognition solidifies AVINATO's position as a leading entity in the maritime industry, showcasing its unwavering commitment to excellence."}`

export default function AboutPage() {

  const [readMoreBtn, setReadMoreBtn] = useState<string>("Read more");
  const [isTrucate, setIsTrucate] = useState<boolean>(true)
  const [textTrucate, setTextTrucate] = useState<any>(trucateText)
  const [cardItem, setCardItem] = useState<any>(aboutListCard[0]);
  const [cardBtnStyle , setCardBtnStyle] = useState<number>(1);

  const cardSelectBtnHandleClick = (cardId:number|any)=>{
    console.log(cardId);
    setCardItem(aboutListCard[cardId-1])
    setCardBtnStyle(cardId)
  }
  const readMorehandleClick = () => {
    if (isTrucate) {
      setReadMoreBtn("Read less")
      setTextTrucate([trucateText, fullText1 , <br/> ,<br/> , fullText2])
      setIsTrucate(!isTrucate)

    } else {
      setReadMoreBtn("Read more")
      setIsTrucate(!isTrucate)
      setTextTrucate(trucateText)
    }
  }
 
  return (
    <>
      <div className='hidden bigTablet:block w-full h-[450px] bg-center bg-cover bg-no-repeat'
      style={{backgroundImage: `url(${BannerBig})`}}
      />

      <div className='w-full bigTablet:hidden block h-[227px] bg-center bg-cover bg-no-repeat'
      style={{backgroundImage: `url(${BannerBig})`}}
      />

      <div className='flex justify-center w-full my-[64px] ourStory'>
        <div className="w-full max-width flex justify-center px-5 bigTbalet:px-0 ">
          <div className='flex flex-col gap-8 bigTablet:gap-6 max-w-[891px] largeDesktop:max-w-[100%] '>
              <div className='text-center title'>
                Our Story
              </div>
              <div className='text '>
                {textTrucate}
                <br/>
                <span className='read-more-btn' onClick={readMorehandleClick}>
                  {readMoreBtn}
                </span>
              </div>


            </div>
        </div>
      </div>

      <div className="flex justify-center w-full pt-[64px] pb-[0px] bigTablet:pb-[160px] bg-[#d9d9d931]">
        <div className="w-full max-width flex justify-center px-[0px] bigTablet:px-[20px] desktop:px-[125px] overflow-hidden">
        <div className='relative flex flex-col justify-start items-center w-full'>
          <img className=" hidden bigTablet:block bigTablet:absolute float-right right-0 -top-36  bigTablet:w-[45vw]" src={illustrationAboutPageImg} />

          <div className="flex z-[50] transition-all duration-1000 ease-out gap-0 overflow-x-auto about-card-btn-container scoll-menu px-5 bigTablet:px-0 ">
            {aboutListCard.map((btn: cardInterface, index: number) => {
              return (
                <div className={`${cardBtnStyle === btn.id ? 'about-card-btn-active' : 'about-card-btn'} transition-all duration-800 ease-out`} key={btn.id} onClick= {()=> cardSelectBtnHandleClick(btn.id)} >{btn.btnTitle}</div>
              )
            })
            }
          </div>

          <div className='w-full flex flex-col-reverse bigTablet:flex-row justify-center about-card mt-[115px] bigTablet:mt-[120px] gap-x-[50px] desktop:gap-x-[88px] gap-y-[64px] bigTablet:gap-y-[0px] '>
            <img className='rounded-[0px] bigTablet:rounded-[24px] max-w-[100%] bigTablet:max-w-[300px] desktop:max-w-[500px] h-[auto]' src={cardItem?.ImgScr} /> 
            <div className='flex flex-col gap-8 about-card-text w-auto customSize:max-w-[566px]'>
              <div className='text-center title customSize:text-left'>{cardItem?.title}</div>
              <div className='text-center description self-center customSize:w-full customSize:text-left'>{cardItem?.description}</div>
            </div>
          </div>
        </div>
        </div>
      </div>

      
      <div className="flex justify-center w-full managerProfil-section">
            <div className="w-full max-width flex flex-col-reverse bigTablet:flex-row justify-center items-center py-[90px] bigTablet:py-[161.5px] gap-x-[77px] px-5">
              <div className='inline-flex flex-col gap-y-6 max-w-[450px]'>
                  <div className='items-center max-w-[90vw] text-center manager-text customSize:text-left '>
                    At all times our service remain personal and flexible, providing you with a
                    complete and reliable on board satisfaction.
                    Our constant work, perseverance and dedication, guarantees you 24 hours of non-stop service with
                    outstanding quality.
                  </div>
                  <div className='items-center max-w-[90vw] text-center manager-name customSize:text-left'>
                    <strong>Sylvain AYAYI</strong>, General Manager at <span className='italic'>"AVINATO"</span>
                  </div>
              </div>
              <div className='OurServingPorts-img'>
                <img src={ManagerProfilImg} alt="manager profil" className='w-[50vw] tablet:w-[377px]' />
              </div>
            </div>
      </div>
      
      <div className="overflow-hidden pb-0  w-ful flex items-center justify-center mt-[100px] mb-[200px]">
            <div className="w-full max-width">
                <PartnersSection />
              </div>
      </div> 
    </>
  )
}
