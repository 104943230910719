import React from 'react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import bannerImg from "./../../assets/images/serviceBanner.jpeg"
import OurServingPortsImg from "./../../assets/images/OurServingPorts_img.png";
import bannerImg2 from "./../../assets/images/serviceBanner2.png"
import repairsMaintenanceIcon from "./../../assets/icons/build-outline.svg"
import foodCabinIcon from "./../../assets/icons/food-apple-outline.svg"
import othersServicesIcon from "./../../assets/icons/customer.svg"
import engineDeckStoresIcon from "./../../assets/icons/bx-store-alt.svg"
import safetyEquipementIcon from "./../../assets/icons/safety-goggles.svg"
import marineOilsIcon from "./../../assets/icons/vacuum-cleaner.svg"
import removalsIcon from "./../../assets/icons/trash.svg"
import britishAdmiralityIcon from "./../../assets/icons/flag-outline.svg"
import {cardInterface} from "../../utilities/cardInterface";
import { useSelectedLink } from './../../utilities/fonctions'


export function Card({IconScr,title,description, id}:cardInterface){

  return(
    <div className='relative sectionDiv flex flex-col items-center py-8 h-max w-fit services-list  w-full bigTablet:w-[343px]' key={id}>
      <div className='rounded-full p-[18px] bg-gray'>
        <ReactSVG src={IconScr} />
      </div>
      <div  className='flex flex-col items-center mt-[52px] gap-4 services-item '>
        <div className='item-title'>{title}</div>
        <div className='item-description'>{description}</div>
      </div>
    </div>
  )
}

export default function ServicesPage() {

  const { selectedLink, ScrollTophandleClick } = useSelectedLink();

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [numElements, setNumElements] = useState<number>(8); // Variable pour contrôler le nombre d'éléments à rendre
  const [viewMoreBtn,setViewMoreBtn] = useState<string>("View more");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth <= 768) {
      setNumElements(4);
    } else {
      setNumElements(8);
    }
  }, [screenWidth]);

  const updateServiceList = () =>{   
    if(numElements === 4){
      setNumElements(8)
      setViewMoreBtn("View less")
    }else if(numElements === 8) {
      setNumElements(4)
      setViewMoreBtn("View more")
    }
  }
  
  const servicesListCard = [ 
          {
          id: 1,
          IconScr: repairsMaintenanceIcon,
          title: "Repairs Maintenance",
          description: "Inspections, Certifications, LIoyd’s App, Davits and Lifeboats, Extinguishers,CO2 Acetylène and Oxyge, Refills, Rewindin."
        },
          {
            id: 2,
            IconScr: foodCabinIcon,
            title: "Food and cabin supplies",
            description: "Bonded Stores, Provisions, Fresh and Froze, Food and Vegetables."
          },
          {
            id: 3,
            IconScr: othersServicesIcon,
            title: "Others Services",
            description: "Fresh water Supplier, Crew Change, Clearance and Shipment, Ship Watching, Periodical inspection"
          },
          {
            id: 4,
            IconScr: engineDeckStoresIcon,
            title: "Engine Deck Stores",
            description: "Electrodes, Steel plates, Shackles, Ropes, Steel pipes, Angles, Elbows, Nuts, Studs, bolts, Cotton Rags, Cutting Tools, Hoses and Couplings, Gloves, ear plugs, Goggles, Respiratory Protection, Protection Clothes, Refrigerant gas"
          },
          {
            id: 5,
            IconScr: safetyEquipementIcon,
            title: "Safety Equipement",
            description: "Pyroyechnics: MOB, Hand flares, Smoke signals, Line throwers Lifejackets, Lifebuoys, Steel pipes, Hoses & Couplings, Signs"
          },
          {
            id: 6,
            IconScr: marineOilsIcon,
            title: "Marine Oils, Solvents, Painting and clearners",
            description: "Lubricants in 200 Ltrs drums, Drums, Shemicals, Cleaners, Cleaning and Painting."
          },
          {
            id: 7,
            IconScr: removalsIcon,
            title: "Removals",
            description: "Garbage Removal, Sludge Removal"
          },
          {
            id: 8,
            IconScr: britishAdmiralityIcon,
            title: "British Admirality",
            description: "Flags, Charts",
          },
      ]
      
 
  return (
    <>
      <div className='hidden bigTablet:block w-full h-[453px] bg-center bg-cover bg-no-repeat'
      style={{backgroundImage: `url(${bannerImg2})`}}
      />
      <div className='relative bigTablet:hidden block w-full h-[227px] bg-center bg-cover bg-no-repeat'
      style={{backgroundImage: `url(${bannerImg})`}}
      >

        <div className="absolute -bottom-[30px] w-full flex justify-center">
          <div className='text-center service-title '>
                Our Services
          </div>
        </div>
        
      </div>
      <div className='flex justify-center w-full ourServices'>
        <div className="w-full max-width flex flex-col items-center pt-[58px] bigTablet:pt-[72px] pb-[61px] px-[20px] desktop:px-[274.5px] ">
            <div className='flex flex-col gap-y-6 mb-8 bigTablet:mb-0 '>
              <div className='text-center title hidden bigTablet:block '>
                  Our Services
              </div>
              <div className='text'>
                With a dynamic team and quality services, we operate as well as alongside as offshore.
              </div>
            </div>

            <div className='w-full flex flex-col items-center services-list'>
              <div className='w-full transition-all duration-1000 gap-x-[144px] grid grid-cols-1 bigTablet:grid-cols-2 '>
                {
                  servicesListCard.slice(0, numElements).map((card: cardInterface)=>{
                      return(
                        <Card IconScr={card.IconScr} title={card.title} description={card.description} key={card.id} />
                      )
                  })
                }
              </div>
              <div className='flex flex-col mt-16'> 
                {screenWidth <= 768 && (
                  <div className='block cursor-pointer bigTablet:hidden view-more-btn' onClick={updateServiceList}>
                    {viewMoreBtn}
                  </div>
                )}
                <Link to="/contact" onClick={() => ScrollTophandleClick('contact-link')}>
                <div className='head-contact-btn mx-auto min-w-[300px] bigTablet:w-[370px] p-4'>
                    Contact us
                  </div>
                </Link>
              </div>
            
            </div>
        </div>
      </div>

      <div className='OurServingPorts-section w-full flex items-center justify-center pt-[124px] pb-[124px]'>
        <div className="w-full max-width px-[20px] desktop:px-[126px] gap-16 desktop:gap-[100px] flex flex-col desktop:flex-row desktop:justify-center items-center">
            <div className='inline-flex flex-col gap-4 px-6 OurServingPorts-text '>
              <div className='OurServingPorts-title'>Our Serving ports</div>
              <div className='OurServingPorts-description'>
                We already operate efficiently in more than five (05) ports in the sub-region
              </div>
            </div>
            <div className='OurServingPorts-img w-[411px] hidden desktop:block'>
              <img src={OurServingPortsImg} alt="Our Serving Ports img" width={411} />
            </div>
        </div>
      </div>
    </>
  )
}
