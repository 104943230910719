import React,{useRef,useState, FormEvent, useEffect} from 'react'
import { ReactSVG } from 'react-svg'
import {ReactComponent as ContactPageIllustration} from "./../../assets/icons/rafiki.svg"
import docIcon from "./../../assets/icons/Google docs.svg"
import trashIcon from "./../../assets/icons/trash2.svg"
import selectFileIcon from "./../../assets/icons/file-earmark-pdf-fill.svg"
import { troncatenerString } from '../../utilities/fonctions'
import axios from 'axios'
import { Loader } from 'react-feather'

interface ResponseProps {
  type: string,
  message: string
}

interface MessageProps {
  name: string
  email: string
  message: string
  companyName: string
  companyAddress: string
  file: Array<any>
}

const messageInitial: MessageProps = {
  name: '',
  email: '',
  message: '',
  companyAddress: '',
  companyName: '',
  file: []
}
const MAX_COUNT = 5;
export const Form: React.FC<{ onFileChange: (files: any[]) => void, selectedFiles: any[]}> = ({ onFileChange, selectedFiles }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false)

  const [fileLimit, setFileLimit] = useState<boolean>(false)
  const [messageForm, setMessageForm] = useState<MessageProps>(messageInitial);
  const [response, setResponse] = useState<ResponseProps | null>(null)


  useEffect(() => {
    const timeId = setTimeout(() => {
      setResponse(null)
    }, 3000)

    return () => {
      clearTimeout(timeId)
    }
  }, [response]);

  const handleFileDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const chosenFiles = Array.prototype.slice.call(e.dataTransfer.files)
      handleUploadFiles(chosenFiles)
    }
  };

  const handleFileInputChange = (e: any) => {
      if (e.target.files && e.target.files.length > 0) {

        let files: any[] = e.target.files;

        let sizeLimited: boolean = false;

        for (let x = 0; x < files.length; x++) {
          let filesize: number = ((files[x].size/1024)/1024); // MB
          if (filesize > 2 ) { 
            sizeLimited = true;
          }
        }

        if(!sizeLimited) {
          const chosenFiles = Array.prototype.slice.call(e.target.files)
          handleUploadFiles(chosenFiles)
        }else {
          setResponse({
            type: 'error',
            message: 'L\'un des fichiers a une taille supérieur à 2MB.'
          })
        }
      }
  };

const handleUploadFiles = (files: any[]) => {
  const uploaded = [...selectedFiles];
  let limitExceeded: boolean = false;
  files.some((file: any) => {
    if (uploaded.findIndex((f: any) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
            setResponse({type: 'error', message: 'Nombre maximal de fichiers atteint.'});
            setFileLimit(false);
            limitExceeded = true;
            return true;
        }
    }
  })
  if(!limitExceeded) onFileChange(uploaded);
}

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (messageForm.name && messageForm.email && messageForm.message) {
      setLoading(true)

      let formData = new FormData();

      selectedFiles.map((file: any) => {
        formData.append('file', file);
      });
      formData.append("name",  messageForm.name);
      formData.append("companyName",  messageForm.companyName);
      formData.append("companyAddress",  messageForm.companyAddress);
      formData.append("email",  messageForm.email);
      formData.append("message",  messageForm.message);

      axios.post('https://avinato.com/api/send-email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        console.log(response);
        setLoading(false)
        onFileChange([])
        setMessageForm(messageInitial)
        setResponse({
          type: 'success',
          message: 'Votre message a été envoyé avec succès!'
        })
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
        setResponse({
          type: 'error',
          message: 'Une erreur s\'est produite, veuillez réessayer!'
        })
      });
    
    }else {
      setResponse({
        type: 'error',
        message: 'Vous devez renseigner votre Nom, E-mail et un message!'
      })
    }
  };

  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-6' >
      <div>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" value={messageForm.name} onChange={(e: any) => {
          setMessageForm({...messageForm, name: e.target.value})
        }} placeholder="Enter your full name" />
      </div>
      <div>
        <label htmlFor="companyName">Company name</label>
        <input type="text" id="companyName" value={messageForm.companyName} onChange={(e: any) => {
          setMessageForm({...messageForm, companyName: e.target.value})
        }}  placeholder="Enter your company name" />
      </div>
      <div>
        <label htmlFor="companyAddress">Company address</label>
        <input type="text" id="companyAddress" value={messageForm.companyAddress} onChange={(e: any) => {
          setMessageForm({...messageForm, companyAddress: e.target.value})
        }} placeholder="Enter your company address" />
      </div>
      <div>
        <label htmlFor="email">E-mail</label>
        <input type="email" id="email" value={messageForm.email} onChange={(e: any) => {
          setMessageForm({...messageForm, email: e.target.value})
        }} placeholder="Enter your e-mail address" />
      </div>
      <div>
        <label htmlFor="message">Message</label>
        <textarea id="message" value={messageForm.message} onChange={(e: any) => {
          setMessageForm({...messageForm, message: e.target.value})
        }} placeholder="Enter your message"></textarea>
      </div>
      <div className='file-input-box cursor-pointer'
      style={{
        pointerEvents: fileLimit ?  'none' : 'initial',
        opacity: fileLimit ?  .5 : 1
      }}
      onClick={() => { if (fileInputRef.current) {
          fileInputRef.current.click();
        }} }>
        <ReactSVG src={selectFileIcon} />
        <input
          type="file"
          id="files"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          style={{ display: 'none' }}
          multiple
          accept='image/*, .pdf'
        />
        {(selectedFiles && selectedFiles.length) ?  <span className='select-file-text'>{selectedFiles.length} file(s) <button type="button" className='browse-file-btn'> Selected </button>
        </span>: <span className='select-file-text'>Drag and drop or <button type="button" className='browse-file-btn'> Browse file</button>
        </span>}
        <div onDrop={handleFileDrop} onDragOver={(e) => e.preventDefault()} />
      </div>
      <button 
      disabled = {
        !messageForm.name ||
        !messageForm.email ||
        !messageForm.message 
      }
      className='submit-conctat-message'
      type="submit"> { loading ? <Loader className="animate-spin" /> : 'Send message' }</button>

      <div className="response-area"
      style={{
        opacity: !response ? "0" : "1",
        transition: "all .8s",
        visibility: !response ? "hidden" : "visible",
      }}
      >
          {response && <span className={`message-span ${response.type === 'error' ? 'error-message': 'success-message'}`} > {response.message} </span>}
      </div>
    </form>
  );
};


export default function ContactPage() {

  const [selectedFiles, setSelectedFiles] = useState<Array<any>>([]);
  const handleFileChange = (files: any[]) => {
    setSelectedFiles(files);
  };

  const handleFileDelete = (fileName: string) => {
    const updatedFiles: any[] = selectedFiles.filter(file => file.name !== fileName);
    setSelectedFiles(updatedFiles)
  };
  
  return (
    <div className='w-full relative sectionDiv overflow-hidden px-5 flex flex-col items-center bigTablet:items-start pt-[0px] bigTablet:pt-[100px] pb-[65px] mb-[100px] gap-[48px]  bigTablet:flex-row bigTablet:justify-center bigTablet:gap-[46px]'>
      <div className='relative px-10 bigTablet:px-0'>
        <ContactPageIllustration className='max-w-[300px] bigTablet:max-w-[100%] max-h-[350px] bigTablet:max-h-[586px]' />

        <div className='flex justify-center mb-8 bigTablet:mt-16 fil'>
          {selectedFiles.length ? (
            <div className='relative w-full'>
              <div className='relative flex flex-col justify-center max-w-[100%] bigTablet:max-w-[560px] gap-3 desktop:justify-start'>
                {selectedFiles.map(file => (
                  <div className='relative flex justify-between w-full gap-4 file-item-box' key={file.name}>
                    <div className='flex items-center gap-3'>
                      <ReactSVG  src={docIcon} />
                      <div className='file-name'> {troncatenerString(file.name)}</div>
                    </div>

                    <div className='cursor-pointer' onClick={() => handleFileDelete(file.name)}>
                      <ReactSVG  src={trashIcon} />
                    </div>
                  </div>
                ))}

              </div>
            </div>
          ): <></>}
        </div>
      </div>
      <div className='flex flex-col gap-6 w-[100%] bigTablet:w-[459px] contact-form-container'>
        <div className='title'>Contact us</div>
        <div className='description'>
          Please fill out the form below and we will get back to you as soon as possible.
        </div>

        <div className='form'>
          <Form onFileChange={handleFileChange} selectedFiles={selectedFiles} />
        </div>
      </div>
      
    </div>
  )
}
